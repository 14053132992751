@import 'src/assets/styles/variables';

body#dashboard {
    background: #f0f2f5;

    .ant-menu-root {
        flex-direction: column;
        display: flex;
        height: 100%;
        border-right: none;
    }

    .title {
        font-size: 20px;
        font-weight: bold;
        margin-bottom: 25px;

        &-primary {
            color: $light-blue-4;
        }

        &-primary-lg {
            font-size: 16px;
            color: $light-blue-4;
        }
    }

    .secondary-button {
        background-color: $button-secondary-color;
        color: black;
        border: 1px solid $button-secondary-outline;
        width: 100px;
    }

    .notification-count {
        float: right;
        margin: 10px;
    }

    .menu-name-too-long {
        width: 300px !important;
        white-space: unset !important;
        line-height: 20px !important;
    }

    .header-container {
        display: flex;
    }

    .page-actions {
        margin-left: auto;

        &-items {
            &-select {
                width: 184px;
            }

            &-search {
                width: 300px;

                .ant-input {
                    padding: 6.9px 12px !important;
                    background: rgba(250, 252, 253, 1);
                }

                .ant-input-search-button {
                    height: 37px !important;
                    background: rgba(250, 252, 253, 1);
                }
            }

            .ant-select-selector {
                height: 37px !important;
                padding: 1px 11px !important;
                background: rgba(250, 252, 253, 1);
            }

            &:not(:last-child) {
                margin-right: 10px;
            }
        }
    }

    .dashboard-content {
        padding: 135px 175px 100px;
        flex-direction: row;

        .ant-layout-sider {
            padding: 25px 0;
            background-color: #fff;
            overflow-x: hidden;
        }

        .ant-menu-item-selected {
            background-color: #f2f7fb;

            &,
            a {
                color: #006cb6;
            }

            &:after {
                border: none;
            }
        }

        .ant-layout-content {
            margin-left: 300px;
            flex: none;
        }

        .ant-breadcrumb {
            margin-bottom: 10px;
        }

        .content {
            background-color: #fff;
            padding: 30px;
            border-radius: 4px;
        }

        .ant-badge-count {
            box-shadow: 0 0 0 1px $red-3;
        }
    }

    .dashboard-sider {
        position: absolute;
        left: 175px;
        height: auto;
        overflow: hidden;

        &.sider-menu {
            padding: 0;
            background-color: transparent;

            &.mobile {
                background-color: #fff;

                .menu-1 {
                    height: auto;
                }
            }
        }
    }

    .menu-layout {
        border-radius: 4px;
        padding: 16px;

        .menu-group-item-icon {
            margin-left: 8px;
        }
    }

    .menu-2 {
        margin-top: 16px;
    }

    .menu-group {
        .ant-menu-submenu-title {
            padding-left: 16px !important;
        }

        .ant-menu-sub.ant-menu-inline {
            background-color: transparent !important;
        }
        .menu-group-title {
            padding: 0 8px;
            color: rgba(0, 0, 0, 0.85);
            display: flex;
            align-items: center;

            .menu-group-title-text {
                margin-left: 10px;
            }
        }

        .menu-group-item {
            padding-left: 56px !important;

            .menu-group-item-content {
                display: flex;
                align-items: center;
            }
        }
    }

    .dashboard-footer {
        background-color: $button-primary-color;
        text-align: center;
        height: 30px;
        padding: 5px 0;
        color: #fff;
        position: fixed;
        bottom: 0;
        width: 100%;
    }

    .dashboard-management {
        .dashboard-profile {
            padding: 0 50px;
            word-wrap: break-word;

            li {
                list-style: none;
            }

            table,
            td {
                border: 1px solid #ccc;
                border-collapse: collapse;
            }

            td.name {
                background-color: $button-primary-color;
                color: white;
                width: 15%;
            }

            td.value {
                word-break: break-all;
                width: 35%;
            }
        }

        .profile-separator {
            border: 1px solid $light-blue-3;
        }

        button.edit-profile {
            margin-left: auto;
            background: $button-primary-color;
            color: #fff;
        }

        .personal-info {
            .title {
                font-size: 16px;
                margin-bottom: 5px;
            }
        }

        button.unsubscribe-button {
            float: right;
        }

        .change-password {
            color: #01a0dd;
            text-decoration: underline;
            margin-bottom: 45px;
            cursor: pointer;
        }
    }

    .dashboard-notifications {
        .title {
            font-size: 24px;
            font-weight: 700;
            line-height: 36px;
            color: #006cb6;
            margin-bottom: 45px;
        }

        .notification {
            &-container {
                padding: 16px 0 16px 16px;
                border-radius: 3px;
                border: 1px solid #d4e1ea;
                margin-bottom: 15px;
                height: 75px;
                display: flex;
                flex-direction: column;
                justify-content: center;
                transition: background-color 0.3s ease-in-out;
                cursor: pointer;

                &_read {
                    background-color: #f4f5f5;
                    &:not(:hover) * {
                        color: #a2adb4 !important;
                    }
                }

                &_unread {
                    background-color: #ffffff;
                }

                &:hover {
                    background-color: #fafafa;

                    & .notification-btn-icon {
                        right: -10px;
                    }
                }
            }

            &-row {
                align-items: center;
            }

            &-status-title-read {
                font-size: 12px;
                font-weight: 400;
                line-height: 18px;
                padding: 4px 8px;
                border: 1px solid #a2adb4;
            }

            &-status-title-unread {
                color: #e95532;
                font-size: 12px;
                font-weight: 400;
                line-height: 18px;
                padding: 4px 8px;
                border: 1px solid #e95532;
            }

            &-date {
                font-size: 14px;
                line-height: 21px;
                font-weight: 700;
                text-align: center;
            }

            &-title {
                font-size: 14px;
                font-weight: 400;
                line-height: 21px;
            }

            &-title-unread {
                color: #2e353a;
                font-size: 14px;
                font-weight: 400;
                line-height: 21px;
            }

            &-title-type-koteki {
                color: #006cb6;
                font-size: 14px;
                font-weight: 400;
                line-height: 21px;
            }

            &-title-type-kisoku {
                color: #109636;
                font-size: 14px;
                font-weight: 400;
                line-height: 21px;
            }

            &-link-read {
                padding-left: 25px;
            }

            &-btn {
                padding-left: 25px;
                border: none;
                background-color: transparent;
            }

            &-btn-icon {
                color: #006cb6;
                font-size: 30px;
                position: relative;
                right: 0;
                transition: right 0.3s ease-in-out;
            }

            &-status-scheduled {
                color: #e97306;
                font-size: 14px;
                font-weight: 400;
                line-height: 21px;
            }

            &-status-finished {
                color: #006cb6;
                font-size: 14px;
                font-weight: 400;
                line-height: 21px;
            }

            &-status-skipped {
                color: $gray-9;
                font-size: 14px;
                font-weight: 400;
                line-height: 21px;
            }

            &-status-cancelled {
                color: $gray-9;
                font-size: 14px;
                font-weight: 400;
                line-height: 21px;
            }
        }

        .pagination {
            margin-top: 45px;

            .custom-pagination .ant-pagination-item,
            .custom-pagination .ant-pagination-prev,
            .custom-pagination .ant-pagination-next,
            .custom-pagination .ant-pagination-item-link {
                border: none !important;
            }

            .custom-pagination .ant-pagination-item-link,
            .custom-pagination .ant-pagination-item {
                font-size: 14px !important;
                font-weight: 400 !important;
                line-height: 21px !important;
                padding: 4px !important;
                color: #006cb6 !important;
            }

            .ant-pagination-item a {
                color: #006cb6 !important;
            }

            .custom-pagination .ant-pagination-item-container {
                position: absolute !important;
                margin-top: 5px !important;
            }

            .custom-pagination .ant-pagination-item-ellipsis {
                color: #006cb6 !important;
            }

            .custom-pagination .ant-pagination-item-active {
                background-color: #006cb6 !important;
                border-radius: 4px !important;

                a {
                    color: #ffffff !important;
                }
            }
        }
    }

    .unsubscribe-modal {
        .unsubscribe-content {
            padding: 30px;
        }

        .unsubscribe-description {
            font-size: 13px;
            font-style: normal;
            font-weight: 400;
            line-height: 22px;
            letter-spacing: 0;
            text-align: center;
        }
    }

    .consultation-list {
        .title {
            color: rgba(0, 108, 182, 1);
            font-size: 24px;
            font-weight: 700;
            margin-bottom: 25px;
        }

        .table-filter {
            float: right;
        }

        .subtitle {
            display: flex;
            align-items: center;
            color: rgba(46, 53, 58, 1);
            font-weight: 400;
            line-height: 21px;
            font-size: 14px;
        }

        &-pagination {
            margin: 15px 0;
        }

        .blue-color-icon {
            color: rgba(0, 108, 182, 1);

            &:hover {
                color: #01a0dd;
            }
        }

        &-table {
            .consultation-list-status {
                display: block;
                width: 100px;
                font-size: 12px;
                font-weight: 400;
                line-height: 18px;
                padding: 4px 10px;
            }

            .status {
                &-matching {
                    background: $orange-1;
                    border: 1px solid $orange-2;
                    color: $orange-3;
                }

                &-selecting-specialist {
                    background: $brown-1;
                    border: 1px solid $brown-2;
                    color: $brown-3;
                }

                &-scheduled {
                    background: $orange-5;
                    border: 1px solid $orange-4;
                    color: $orange-4;
                }

                &-finished {
                    background: $button-secondary-color;
                    border: 1px solid $blue-1;
                    color: $blue-1;
                }

                &-skipped {
                    background: $gray-8;
                    border: 1px solid $gray-9;
                    color: $gray-9;
                }

                &-no-match {
                    background: $purple-1;
                    border: 1px solid $purple-3;
                    color: $purple-3;
                }

                &-canceled {
                    background: $button-secondary-color;
                    border: 1px solid $gray-9;
                    color: $gray-9;
                }

                &-requesting {
                    background: $teal-1;
                    border: 1px solid $teal-2;
                    color: $teal-2;
                }

                &-waiting-conf {
                    background: $orange-1;
                    border: 1px solid $orange-2;
                    color: $orange-3;
                }

                &-past-due {
                    background: $button-secondary-color;
                    border: 1px solid $gray-9;
                    color: $gray-9;
                }
            }

            .ant-table {
                table {
                    border-spacing: 0 1em !important;
                }

                .ant-table-thead {
                    .ant-table-cell {
                        background-color: transparent !important;

                        &::before {
                            height: 0 !important;
                        }
                    }

                    & > tr > th {
                        border-bottom: 0 !important;
                    }
                }

                .ant-table-tbody {
                    & > tr > td {
                        border-top: 1px solid rgba(212, 225, 234, 1) !important;
                        border-bottom: 1px solid rgba(212, 225, 234, 1) !important;
                    }

                    & > tr.ant-table-measure-row {
                        display: none;
                    }

                    & > tr > td:first-child {
                        border-left: 1px solid rgba(212, 225, 234, 1) !important;
                        border-radius: 4px 0 0 4px !important;
                    }

                    & > tr > td:last-child {
                        border-right: 1px solid rgba(212, 225, 234, 1) !important;
                        border-radius: 0 4px 4px 0 !important;
                    }

                    & > tr.custom-row-class {
                        cursor: pointer !important;
                    }
                }

                .column-title {
                    font-size: 12px !important;
                    font-weight: bold !important;
                }

                .custom-row-class {
                    height: 77.25px;

                    .blue-color-icon {
                        position: relative;
                        right: 0;
                        transition: right 0.3s ease-in-out;
                    }

                    &:hover {
                        .blue-color-icon {
                            right: -10px;
                        }
                    }
                }
            }
        }
    }

    // .change-sched-modal {
    //     width: 70vw !important;
    // }

    .change-confirmation-modal {
        width: 700px !important;

        .title {
            font-size: 32px;
            line-height: 48px;
            font-weight: 700;
            text-align: center;
            color: #2e353a;
            margin-bottom: 40px !important;
        }

        .details-div-top {
            padding: 16px !important;
            border-top: 1px solid #d4e1ea;
            border-bottom: 1px solid #d4e1ea;
        }

        .details-div {
            padding: 16px !important;
            border-bottom: 1px solid #d4e1ea;
            margin-bottom: 20px !important;
        }

        .details-row {
            align-items: center !important;
        }

        .details-label {
            font-size: 14px;
            line-height: 21px;
            font-weight: 400;
            text-align: left;
            color: #2e353a;
            border-right: 1px solid #d4e1ea;
        }

        .details-value {
            white-space: pre-line;
            font-size: 14px;
            line-height: 21px;
            font-weight: 400;
            text-align: left;
            color: #2e353a;
            padding-left: 32px;
        }

        .details-date {
            font-size: 16px;
            line-height: 24px;
            font-weight: 700;
            text-align: left;
            color: #006cb6;
            padding-left: 32px;
        }

        .btn-back {
            width: 180px !important;
            border: 1px solid #006cb6 !important;
            border-radius: 27px !important;
            padding: 8px 32px 32px !important;

            .btn-back-text {
                font-size: 16px;
                line-height: 24px;
                font-weight: 700;
                text-align: center;
                color: #006cb6;
            }

            .btn-icon {
                font-size: 14px;
                padding-right: 10px;
            }
        }

        .btn-next {
            width: 320px !important;
            border-radius: 27px !important;
            border: 1px solid #006cb6 !important;
            background-color: #006cb6 !important;
            padding: 8px 32px 32px !important;

            .btn-next-text {
                font-size: 16px;
                line-height: 24px;
                font-weight: 500;
                text-align: center;
                color: #ffffff;
            }
        }
    }

    //appointment changed modal
    .appointment-changed-modal {
        width: 800px !important;
        margin-top: -20px !important;

        .title {
            font-size: 32px;
            line-height: 48px;
            font-weight: 700;
            text-align: center;
            color: #2e353a;
            margin: 32px 0 !important;
        }

        .subtitle {
            font-size: 14px;
            line-height: 21px;
            font-weight: 400;
            text-align: center;
            color: #2e353a;
        }

        .subtitle-top {
            margin-bottom: 5px !important;
        }

        .subtitle-bottom {
            margin-bottom: 40px !important;
        }

        .appointment-changed-div {
            padding: 16px !important;
            border-top: 1px solid #d4e1ea;
            border-bottom: 1px solid #d4e1ea;
        }

        .appointment-changed-row {
            align-items: center !important;
        }

        .appointment-changed-label {
            font-size: 14px;
            line-height: 21px;
            font-weight: 400;
            text-align: left;
            color: #2e353a;
            border-right: 1px solid #d4e1ea;
        }

        .appointment-changed-date {
            font-size: 16px;
            line-height: 24px;
            font-weight: 700;
            text-align: left;
            color: #006cb6;
            padding-left: 32px;
        }

        .action-button {
            margin-top: 40px !important;
            text-align: center !important;
        }

        .btn-close {
            width: 180px !important;
            border: 1px solid #006cb6 !important;
            border-radius: 27px !important;
            padding: 8px 32px 32px !important;

            .btn-close-text {
                font-size: 16px;
                line-height: 24px;
                font-weight: 700;
                text-align: center;
                color: #006cb6;
            }

            .btn-icon {
                font-size: 14px;
                padding-right: 10px;
            }
        }
    }
    //end

    .kouteki-title {
        font-weight: 700;
        font-size: 32px;
        text-align: center;
    }

    .separation-color {
        color: #d4e1ea !important;
    }

    .copy-clipboard {
        cursor: pointer;
    }

    .round-button {
        border-radius: 32px !important;
    }

    .font-weight-700 {
        font-weight: 700;
    }

    .fs-16 {
        font-size: 16px;
    }

    .fs-20 {
        font-size: 20px;
    }

    // Calendar customization
    .change-schedule-modal {
        /* Default width */
        width: 968px !important;

        // Reduce modal size
        .ant-modal-content {
            zoom: 0.78;
            flex-direction: column;
            overflow: hidden;
            font-size: 0.75rem;
        }

        // Reduce calendar section size to fit 100% screen size
        .schedule-time > label {
            height: 49px;
            padding-top: 13px !important;
        }

        // Reduce calendar section size to fit 100% screen size
        .ant-picker-panel .ant-picker-calendar-date {
            height: 60px !important;
            font-size: 0.75rem !important;
        }

        .ant-modal-body {
            padding: 35px !important;
        }

        .title {
            font-size: 32px;
            line-height: 48px;
            font-weight: 700;
            text-align: center;
            color: #2e353a;
            margin-bottom: 40px !important;
        }

        .details-div-top {
            padding: 16px !important;
            border-top: 1px solid #d4e1ea;
            border-bottom: 1px solid #d4e1ea;
        }

        .details-div {
            padding: 16px !important;
            border-bottom: 1px solid #d4e1ea;
            margin-bottom: 40px !important;
        }

        .details-row {
            align-items: center !important;
        }

        .details-label {
            font-size: 16px;
            line-height: 21px;
            font-weight: 400;
            text-align: left;
            color: #2e353a;
            border-right: 1px solid #d4e1ea;
        }

        .details-value {
            white-space: pre-line;
            font-size: 16px;
            line-height: 21px;
            font-weight: 400;
            text-align: left;
            color: #2e353a;
            padding-left: 32px;
        }

        .schedule-calendar {
            margin-bottom: 40px !important;

            //Calendar customization
            .ant-picker-calendar-date-value {
                text-align: left !important;
                font-size: 12px !important;
                font-weight: 400 !important;
                line-height: 24px !important;
            }

            .ant-picker-cell {
                border: 1px solid #cccccc !important;
            }

            .ant-picker-cell-inner {
                border: none !important;
            }

            .ant-picker-content thead {
                border: 1px solid #cccccc !important;
            }

            .ant-picker-content tr {
                height: 48px;
                text-align: center !important;
            }

            .ant-picker-content th {
                border-right: 1px solid #cccccc;
                font-size: 16px !important;
                font-weight: 400 !important;
                line-height: 24px !important;
                padding: 0 !important;
            }
        }

        .schedule-time-list {
            max-width: 22%;
            margin-left: 34px;
            margin-top: 11px !important;
        }

        .schedule-summary {
            font-size: 18px;
            line-height: 22px;
            color: black;
            font-weight: bolder;

            div {
                display: inline-block;
                border-bottom: 2px solid $blue-1;
                line-height: 25px;

                span.schedule-datetime {
                    color: $blue-1;
                }
            }
        }

        .schedule-icons {
            text-align: right;
            padding-right: 0 !important;
            color: #2e353a;
            font-weight: 400;
            font-size: 12px;
            line-height: 18px;
            span {
                margin: 0 10px !important;
            }
            img {
                margin-bottom: 3px;
            }
        }

        .schedule-checker-icon {
            color: #e95532;
            display: flex;
            justify-content: center;
            align-items: center;
            height: 23%;

            svg circle {
                stroke: #006cb6;
            }
        }

        .ant-picker-panel .ant-picker-calendar-date {
            height: 75px;
            padding: 4px 12px 0;
            margin: 0;
            border-top: none;
        }

        .ant-picker-calendar-full .ant-picker-panel .ant-picker-cell-selected .ant-picker-calendar-date {
            background-color: #006cb6 !important;

            .ant-picker-calendar-date-value {
                color: #ffffff;
            }

            .schedule-checker-icon {
                svg circle {
                    stroke: #ffffff;
                }
            }
        }

        .ant-picker-cell {
            &:hover {
                &:not(.ant-picker-cell-selected):not(.ant-picker-cell-range-start):not(.ant-picker-cell-range-end):not(
                        .ant-picker-cell-range-hover-start
                    ):not(.ant-picker-cell-range-hover-end) {
                    .ant-picker-cell-inner {
                        background: #e5f0f7;
                    }
                }
            }
        }

        .month-picker {
            display: flex;
            align-items: center;

            &-text {
                font-weight: bold;
                font-size: 16px;
            }

            button {
                font-size: 8px;
                padding: 0 5px;
                margin: 0 10px !important;
                height: 20px;
                border: 1px solid #006cb6;
                color: #006cb6;
            }
        }

        .schedule-time {
            width: 100% !important;
            text-align: center;

            .ant-radio-wrapper {
                border: 1px solid #a2adb4;
                border-radius: 4px;
                padding: 20px;
                margin: 6px 0;
                width: 100%;
                justify-content: center;
                transition: background-color 0.2s ease-in-out;

                &:hover {
                    background-color: #e5f0f7;
                }
            }

            .ant-radio-wrapper-disabled {
                background-color: #f4f5f5;
            }

            .ant-radio-wrapper-checked {
                border: 2px solid #006cb6;
            }
        }

        .ant-picker-cell-disabled {
            background-color: #f4f5f5;
            // color: #99C4E1 !important;
        }

        // Change color of sunday header
        .schedule-calendar > div > div > div > table > thead > tr > th:nth-child(1) {
            color: #e95532;
        }

        // Change color of sunday date
        .schedule-calendar > div > div > div > table > tbody > tr > td:nth-child(1) {
            color: #f6bbad !important;
        }

        // Change color of saturday date
        .schedule-calendar > div > div > div > table > thead > tr > th:nth-child(7) {
            color: #006cb6 !important;
        }

        // Change color of sunday date
        .schedule-calendar > div > div > div > table > tbody > tr > td:nth-child(7) {
            color: #99c4e1 !important;
        }

        .calendar-error-message {
            color: #e95532;
            margin-top: 10px;
        }

        .btn-close {
            width: 180px !important;
            border: 1px solid #006cb6 !important;
            border-radius: 27px !important;
            padding: 8px 32px 32px !important;

            .btn-close-text {
                font-size: 16px;
                line-height: 24px;
                font-weight: 700;
                text-align: center;
                color: #006cb6;
            }

            .btn-icon {
                font-size: 14px;
                padding-right: 10px;
            }
        }

        .col-next {
            margin-left: 40px !important;
            text-align: right !important;
        }

        .btn-next {
            width: 320px !important;
            border-radius: 27px !important;
            border: 1px solid #006cb6 !important;
            background-color: #006cb6 !important;
            padding: 8px 32px 32px !important;

            .btn-next:disabled {
                color: gray !important;
                background-color: #ccc !important; /* Optional: Change background color */
                border-color: gray !important; /* Optional: Change border color */
            }

            .btn-next-text {
                font-size: 16px;
                line-height: 24px;
                font-weight: 500;
                text-align: center;
                color: #ffffff;
            }
        }
    }
    //end

    .fc-lb-6 {
        color: $light-blue-6;
    }

    .fc-white {
        color: white;
    }

    .bg-lb-6 {
        background-color: $light-blue-6;
    }

    .disabled-button {
        background-color: #f4f5f5 !important;
        color: $gray-4 !important;
        cursor: not-allowed !important;
    }

    .justify-self-center {
        justify-self: center;
    }

    .schedule-time {
        width: 15% !important;
        text-align: center;
    }

    .change-sched-primary-btn {
        width: 50%;
        height: 45px;
        background-color: $blue-1;
        color: #ffffff;
    }

    .change-sched-primary-btn:disabled {
        color: gray !important;
        background-color: #ccc !important;
        border-color: #ccc !important;
    }

    .change-sched-secondary-btn {
        width: 25%;
        height: 45px;
        background-color: #ffffff;
        color: $blue-1;
        border-color: $blue-1;
        margin-right: 30px !important;
    }

    .consultation-details {
        .requirement-title {
            font-weight: 700;
            font-size: 17px;
        }

        .primary-btn {
            width: auto;
            background-color: $orange-4;
            color: white;
        }

        .secondary-button {
            margin-left: 20px;
            width: auto;
            color: $orange-4;
            border-color: $orange-4;
            background-color: white;
        }

        @keyframes inAnimation {
            0% {
                opacity: 0;
                visibility: hidden;
            }
            100% {
                opacity: 1;
                visibility: visible;
            }
        }

        @keyframes outAnimation {
            0% {
                opacity: 1;
            }
            100% {
                opacity: 0;
                visibility: hidden;
            }
        }

        .meeting-details {
            display: flex;
            font-size: 14px;
            margin-bottom: 40px;

            &-discussion-title {
                font-size: 20px;
                color: $blue-1;
                font-weight: 700;
            }

            &-date {
                font-size: 16px;
                color: $blue-1;
                font-weight: 700;
            }

            &-show-transition {
                transition: all 2s;
                opacity: 0;
                display: none;
            }

            &-opacity-change {
                opacity: 1;
                display: flex;
            }

            &-background {
                background-color: $gray-8;
                align-items: center;
                border-radius: 4px !important;
            }

            &-requirements {
                background-color: #ffffff;
                padding: 12px;
            }

            &-border {
                border-color: #d4e1ea !important;
            }

            &-divider,
            &-label-title {
                align-items: center !important;
            }

            &-remarks-title {
                font-size: 14px;
                font-weight: 700;
            }

            &-title {
                margin: 0 25px 0 10px;
                align-content: center;
                text-align: center !important;
                color: #006cb6;
                font-size: 24px;
                font-weight: 700 !important;
                line-height: 36px;
            }

            &-fund-title {
                margin: 0 25px 0 10px !important;
                align-content: center !important;
                color: #2e353a !important;
                font-size: 14px !important;
                font-weight: 700 !important;
                line-height: 21px !important;
            }

            &-card {
                width: 110px !important;
            }

            &-status {
                margin: auto;
                padding: 2px 12px !important;
            }

            &-back-button {
                color: $blue-1;
                border-color: $blue-1;
            }

            &-back-button:hover {
                background-color: $gray-8;
            }

            &-accordion-button {
                color: white;
                border-color: $blue-1;
                background-color: $blue-1;
            }

            .card-container {
                display: flex;
                justify-content: center;
                align-self: center;
            }

            .status {
                &-scheduled {
                    color: #e97306 !important;
                    font-weight: 400 !important;
                }

                &-finished {
                    color: #006cb6 !important;
                    font-weight: 400 !important;
                }

                &-cancelled {
                    color: #848f97 !important;
                    font-weight: 400 !important;
                }

                &-not-implemented {
                    color: #848f97 !important;
                    font-weight: 400 !important;
                }

                &-default {
                    color: #848f97 !important;
                    font-weight: 400 !important;
                }
            }
        }

        .grant-checklist {
            margin: 10px 0;
        }

        .consultation-detail {
            margin: 10px 0;

            textarea:disabled {
                background-color: #ffffff !important;
            }
        }

        .cancel-button {
            float: right;
        }

        button.back-button {
            width: 200px;
            margin-top: 30px;
        }

        button.consult {
            margin-top: 30px;
        }

        .consult-note {
            margin: 20px auto;
            width: 60%;
        }
    }

    .dashboard-calendar {
        .meeting-count {
            padding: 15px 20px;
            border-radius: 3px;
            border: 1px solid #006cb6;
            margin-bottom: 15px;
            color: black !important;
            transition: background-color 0.3s ease-in-out;
            cursor: pointer;

            &-title {
                font-size: 16px;
                font-weight: bold;
            }

            & .meeting-count-arrow {
                position: relative;
                right: 0;
                transition: right 0.3s ease-in-out;
            }

            &:hover {
                background-color: #fafafa;

                .meeting-count-arrow {
                    right: -10px;
                }
            }
        }

        .ant-picker-calendar-full {
            .ant-picker-panel {
                min-width: 130px !important;
            }

            .month-picker-text {
                font-size: 16px;
                font-weight: bold;
            }
        }

        .ant-picker-calendar-date-content {
            height: auto !important;
            min-height: 100px;
            display: flex;
            flex-direction: column;
            justify-content: flex-start;
            align-items: flex-start;
            padding: 5px;
            box-sizing: border-box;
            overflow: hidden;
        }

        .events {
            margin: 0;
            padding: 0;
            list-style: none;
            height: auto;
            overflow: visible !important;
            line-height: 1.5;
        }

        .wrap-tag {
            margin-bottom: 5px;
            font-size: 10px;
            padding: 2px 4px;

            a {
                display: -webkit-box;
                -webkit-line-clamp: 2;
                -webkit-box-orient: vertical;
                overflow: hidden;
                text-overflow: ellipsis;
                word-break: break-word;
                white-space: normal;
            }
        }

        // Specific only to calendar component
        .ant-picker-cell-disabled {
            background-color: #f4f5f5;
            color: #99c4e1 !important;
        }

        .ant-picker-calendar-date-today {
            background-color: #f8fade;
        }

        // Change color of sunday header
        .schedule-calendar > div > div > div > table > thead > tr > th:nth-child(1) {
            color: red;
        }

        // Change color of sunday date
        .schedule-calendar > div > div > div > table > tbody > tr > td:nth-child(1) {
            color: #f6bbad !important;
        }

        // Change color of saturday date
        .schedule-calendar > div > div > div > table > thead > tr > th:nth-child(7) {
            color: #006cb6 !important;
        }

        // Add border to header calendar and text-centered
        .schedule-calendar > div > div > div > table > thead > tr > th {
            border: 1px solid #cccccc;
            text-align: center;
        }

        // Add border to date cells
        .schedule-calendar > div > div > div > table > tbody > tr > td {
            border: 1px solid #cccccc;
        }

        .schedule-calendar {
            .ant-tag-green {
                border-color: #109636;
                background-color: #eaf2e8;

                a {
                    color: #109636;
                }
            }

            .ant-tag-blue {
                border-color: #006cb6;
                background-color: #e5f0f7;

                a {
                    color: #006cb6;
                }
            }
        }

        .ant-picker-calendar-date-value {
            text-align: start !important;
        }

        .calendar-icon {
            img {
                margin: 10px 8px;
            }
        }

        .meeting-count-title {
            img {
                margin-right: 13px;
            }

            color: #2e353a;
        }

        .meeting-count-display {
            span {
                color: #006cb6;
            }

            color: #2e353a;
            font-size: 14px !important;
            background-color: #f2f7fb;
            display: inline-block;
            padding: 2px 20px;
            border-radius: 4px;
        }

        .meeting-count-arrow {
            text-align: right;
            color: #006cb6;
            font-size: 20px;
        }

        .title {
            color: #006cb6;
            font-size: 24px;
        }

        .divider {
            margin: 30px 0;
            height: 2px;
            border: none;
            background-color: $button-primary-color;
        }

        .subtitle {
            font-size: 14px;
            color: $gray-4;
        }

        .ant-picker-body {
            z-index: 0;
        }

        .month-picker {
            display: flex;
            align-items: center;
            justify-content: center;
            text-align: center;
            gap: 10px;
        }

        .month-picker button {
            font-size: 8px;
            padding: 0 5px;
            margin: 0 20px !important;
            height: 20px;
            border: 1px solid #006cb6;
            color: #006cb6;
        }

        ul {
            list-style-type: none !important;
            padding-inline-start: 0;
        }
    }

    .specialist-review {
        &-card {
            background: $gray-2;
            border-color: $gray-3;
            margin: 10px 0;

            .ant-card-body {
                padding: 15px;
            }
        }

        &-pagination {
            margin: 15px 0;
        }

        &-image {
            height: 50px;
        }

        &-name {
            font-size: 18px;
        }

        &-done {
            color: $button-primary-color;
        }

        .specialist-label {
            color: $gray-4;
        }

        .ant-btn-primary {
            background: $button-primary-color;
        }

        .ant-btn-primary[disabled] {
            background: $gray-2;
        }

        .fund-info {
            background: $light-blue-1;
            border-color: $light-blue-1;

            .ant-card-body {
                padding: 15px;
            }
        }

        .feedback-container {
            margin: 15px 0;
            background: $gray-2;
            border-color: $gray-3;

            .ant-card-body {
                padding: 15px;
            }

            h1 {
                font-size: 16px;
                text-decoration: underline;
                margin-bottom: 10px;
            }

            h2 {
                font-size: 12px;
                margin-bottom: 5px;
            }

            h3 {
                font-size: 14px;
                margin: 10px 0;
            }
        }

        .feedback-buttons {
            .ant-btn {
                width: 200px;

                &:not(:last-child) {
                    margin-right: 20px;
                }
            }
        }
    }

    //start kisoku modals
    .schedule-meeting-modal {
        /* Default width */
        width: 968px !important;

        // Reduce modal size
        .ant-modal-content {
            zoom: 0.78;
            flex-direction: column;
            overflow: hidden;
            font-size: 0.75rem;
        }

        // Reduce calendar section size to fit 100% screen size
        .schedule-time > label {
            height: 49px;
            padding-top: 13px !important;
        }

        // Reduce calendar section size to fit 100% screen size
        .ant-picker-panel .ant-picker-calendar-date {
            height: 60px !important;
            font-size: 0.75rem !important;
        }

        .ant-modal-body {
            padding: 35px !important;
        }

        .title {
            font-size: 32px;
            line-height: 48px;
            font-weight: 700;
            text-align: center;
            color: #2e353a;
            margin-top: 32px !important;
            margin-bottom: 8px !important;
        }

        .subtitle {
            font-size: 14px;
            line-height: 21px;
            font-weight: 700;
            text-align: center;
            color: #2e353a;
            margin-bottom: 40px !important;
        }

        .note {
            font-size: 14px;
            line-height: 21px;
            font-weight: 700;
            text-align: center;
            color: #2e353a;
        }

        .note-required {
            font-size: 14px;
            line-height: 21px;
            font-weight: 400;
            text-align: center;
            color: #e95532;
            padding-left: 5px;
        }

        .note-divider {
            margin-top: 0 !important;
            border: 1px solid #d4e1ea !important;
            margin-bottom: 24px !important;
        }

        .schedule-calendar {
            margin-bottom: 40px !important;

            // Calendar customization
            .ant-picker-calendar-date-value {
                text-align: left !important;
                font-size: 16px !important;
                font-weight: 400 !important;
                line-height: 24px !important;
            }

            .ant-picker-cell {
                border: 1px solid #cccccc !important;
            }

            .ant-picker-cell-inner {
                border: none !important;
            }

            .ant-picker-content thead {
                border: 1px solid #cccccc !important;
            }

            .ant-picker-content tr {
                height: 48px;
                text-align: center !important;
            }

            .ant-picker-content th {
                border-right: 1px solid #cccccc;
                font-size: 16px !important;
                font-weight: 400 !important;
                line-height: 24px !important;
                padding: 0 !important;
            }
        }

        .schedule-time-list {
            max-width: 22%;
            margin-left: 34px;
            margin-top: 11px !important;
        }

        .schedule-summary {
            font-size: 18px;
            line-height: 22px;
            color: black;
            font-weight: bolder;

            div {
                display: inline-block;
                border-bottom: 2px solid $blue-1;
                line-height: 25px;

                span.schedule-datetime {
                    color: $blue-1;
                }
            }
        }

        .schedule-icons {
            text-align: right;
            padding-right: 0 !important;
            color: #2e353a;
            font-weight: 400;
            font-size: 12px;
            line-height: 18px;
            span {
                margin: 0 10px !important;
            }
            img {
                margin-bottom: 3px;
            }
        }

        .schedule-checker-icon {
            color: #e95532;
            display: flex;
            justify-content: center;
            align-items: center;
            height: 21%;

            svg circle {
                stroke: #006cb6;
            }
        }

        .ant-picker-panel .ant-picker-calendar-date {
            height: 75px;
            padding: 4px 12px 0;
            margin: 0;
            border-top: none;
        }

        .ant-picker-calendar-full .ant-picker-panel .ant-picker-cell-selected .ant-picker-calendar-date {
            background-color: #006cb6 !important;

            .ant-picker-calendar-date-value {
                color: #ffffff;
            }

            .schedule-checker-icon {
                svg circle {
                    stroke: #ffffff;
                }
            }
        }

        .ant-picker-cell {
            &:hover {
                &:not(.ant-picker-cell-selected):not(.ant-picker-cell-range-start):not(.ant-picker-cell-range-end):not(
                        .ant-picker-cell-range-hover-start
                    ):not(.ant-picker-cell-range-hover-end) {
                    .ant-picker-cell-inner {
                        background: #e5f0f7;
                    }
                }
            }
        }

        .month-picker {
            display: flex;
            align-items: center;

            &-text {
                font-weight: bold;
                font-size: 16px;
            }

            button {
                font-size: 8px;
                padding: 0 5px;
                margin: 0 10px !important;
                height: 20px;
                border: 1px solid #006cb6;
                color: #006cb6;
            }
        }

        .schedule-time {
            width: 100% !important;
            text-align: center;
        }

        .schedule-time {
            .ant-radio-wrapper {
                border: 1px solid #a2adb4;
                border-radius: 4px;
                padding: 20px;
                margin: 6px 0;
                width: 100%;
                justify-content: center;
                transition: background-color 0.2s ease-in-out;

                &:hover {
                    background-color: #e5f0f7;
                }
            }

            .ant-radio-wrapper-disabled {
                background-color: #f4f5f5;
            }

            .ant-radio-wrapper-checked {
                border: 2px solid #006cb6;
            }
        }

        .ant-picker-cell-disabled {
            background-color: #f4f5f5;
        }

        // Change color of sunday header
        .schedule-calendar > div > div > div > table > thead > tr > th:nth-child(1) {
            color: #e95532;
        }

        // Change color of sunday date
        .schedule-calendar > div > div > div > table > tbody > tr > td:nth-child(1) {
            color: #f6bbad !important;
        }

        // Change color of saturday date
        .schedule-calendar > div > div > div > table > thead > tr > th:nth-child(7) {
            color: #006cb6 !important;
        }

        // Change color of sunday date
        .schedule-calendar > div > div > div > table > tbody > tr > td:nth-child(7) {
            color: #99c4e1 !important;
        }

        .calendar-error-message {
            color: #e95532;
            margin-top: 10px;
        }

        .btn-close {
            width: 180px !important;
            border: 1px solid #006cb6 !important;
            border-radius: 27px !important;
            padding: 8px 32px 32px !important;

            .btn-close-text {
                font-size: 16px;
                line-height: 24px;
                font-weight: 700;
                text-align: center;
                color: #006cb6;
            }

            .btn-icon {
                font-size: 14px;
                padding-right: 10px;
            }
        }

        .col-next {
            text-align: right;
        }

        .btn-next:disabled {
            color: gray !important;
            background-color: #ccc !important;
            border-color: gray !important;
        }

        .btn-next {
            width: 320px !important;
            border-radius: 27px !important;
            border: 1px solid #006cb6 !important;
            background-color: #006cb6 !important;
            padding: 8px 32px 32px !important;

            .btn-next-text {
                font-size: 16px;
                line-height: 24px;
                font-weight: 700;
                text-align: center;
                color: #ffffff;
            }
        }
    }

    .request-meeting-modal {
        width: 700px !important;
        margin-top: -20px !important;

        .title {
            font-size: 32px;
            line-height: 48px;
            font-weight: 700;
            text-align: center;
            color: #2e353a;
            margin-bottom: 8px !important;
        }

        .subtitle {
            font-size: 14px;
            line-height: 21px;
            font-weight: 700;
            text-align: center;
            color: #2e353a;
            margin-bottom: 40px !important;
        }

        .note {
            font-size: 14px;
            line-height: 21px;
            font-weight: 700;
            text-align: center;
            color: #2e353a;
        }

        .note-required {
            font-size: 14px;
            line-height: 21px;
            font-weight: 400;
            text-align: center;
            color: #e95532;
            padding-left: 5px;
        }

        .gray-note-required {
            font-size: 14px;
            line-height: 21px;
            font-weight: 400;
            text-align: center;
            color: $gray-9;
            padding-left: 5px;
        }

        .note-divider {
            margin-top: 0 !important;
            border: 1px solid #d4e1ea !important;
            margin-bottom: 15px !important;
        }

        .ant-modal-body {
            padding: 50px 10% !important;
        }

        form {
            width: 100%;
            margin-top: 20px !important;
            margin-bottom: 0 !important;
        }

        .kisoku-title-dropdown {
            width: 30% !important;
            margin-bottom: 40px !important;
        }

        .text-area-placeholder {
            font-size: 14px;
            line-height: 21px;
            font-weight: 400;
            padding-left: 15px;
        }

        ul {
            padding-left: 20px;
        }

        .daido-joins-card {
            border: 1px solid $gray-3;
            background: $gray-1;

            .ant-card-body {
                padding: 15px;
            }
        }

        .daido-radio {
            margin-bottom: 10px !important;
        }

        .daido-note {
            text-align: left !important;
        }

        .daido-first-note {
            margin-bottom: 5px !important;
        }

        .meeting-request-card {
            border: 1px solid $light-blue-2;
            background: $light-blue-1;
        }

        .action-buttons {
            margin-top: 40px !important;
        }

        .btn-close {
            width: 180px !important;
            border: 1px solid #006cb6 !important;
            border-radius: 27px !important;
            padding: 8px 32px 32px !important;

            .btn-close-text {
                font-size: 16px;
                line-height: 24px;
                font-weight: 700;
                text-align: center;
                color: #006cb6;
            }

            .btn-icon {
                font-size: 14px;
                padding-right: 10px;
            }
        }

        .btn-submit {
            width: 260px !important;
            border-radius: 27px !important;
            border: 1px solid #006cb6 !important;
            background-color: #006cb6 !important;
            padding: 8px 32px 32px !important;

            .btn-submit-text {
                font-size: 16px;
                line-height: 24px;
                font-weight: 500;
                text-align: center;
                color: #ffffff;
            }
        }
    }

    .meeting-confirmation-modal {
        width: 700px !important;
        margin-top: -20px !important;

        .title {
            font-size: 32px;
            line-height: 48px;
            font-weight: 700;
            text-align: center;
            color: #2e353a;
            margin: 32px 0 !important;
        }

        .confirmation-div-top {
            padding: 16px !important;
            border-top: 1px solid #d4e1ea;
            border-bottom: 1px solid #d4e1ea;
        }

        .confirmation-div {
            padding: 16px !important;
            border-bottom: 1px solid #d4e1ea;
        }

        .confirmation-row {
            align-items: center !important;
        }

        .confirmation-label {
            font-size: 14px;
            line-height: 21px;
            font-weight: 400;
            text-align: left;
            color: #2e353a;
            border-right: 1px solid #d4e1ea;
        }

        .confirmation-value {
            font-size: 14px;
            line-height: 21px;
            font-weight: 400;
            text-align: left;
            color: #2e353a;
            padding-left: 32px;
        }

        .confirmation-list {
            font-size: 14px;
            line-height: 21px;
            font-weight: 400;
            text-align: left;
            color: #2e353a;
            padding-left: 10px;
        }

        .confirmation-date {
            font-size: 16px;
            line-height: 24px;
            font-weight: 700;
            text-align: left;
            color: #006cb6;
            padding-left: 32px;
        }

        .btn-close {
            width: 180px !important;
            border: 1px solid #006cb6 !important;
            border-radius: 27px !important;
            padding: 8px 32px 32px !important;

            .btn-close-text {
                font-size: 16px;
                line-height: 24px;
                font-weight: 700;
                text-align: center;
                color: #006cb6;
            }

            .btn-icon {
                font-size: 14px;
                padding-right: 10px;
            }
        }

        .btn-submit {
            width: 260px !important;
            border-radius: 27px !important;
            border: 1px solid #006cb6 !important;
            background-color: #006cb6 !important;
            padding: 8px 32px 32px !important;

            .btn-submit-text {
                font-size: 16px;
                line-height: 24px;
                font-weight: 500;
                text-align: center;
                color: #ffffff;
            }
        }

        .col-submit {
            text-align: right;
        }

        .action-buttons {
            margin-top: 40px !important;
        }
    }

    .appointment-booked-modal {
        width: 800px !important;
        margin-top: -20px !important;

        .title {
            font-size: 32px;
            line-height: 48px;
            font-weight: 700;
            text-align: center;
            color: #2e353a;
            margin: 32px 0 !important;
        }

        .subtitle {
            font-size: 16px;
            line-height: 24px;
            font-weight: 400;
            text-align: center;
            color: #2e353a;
        }

        .subtitle-top {
            margin-bottom: 5px !important;
        }

        .subtitle-bottom {
            margin-bottom: 40px !important;
        }

        .appointment-booked-div {
            padding: 16px !important;
            border-top: 1px solid #d4e1ea;
            border-bottom: 1px solid #d4e1ea;
        }

        .appointment-booked-row {
            align-items: center !important;
        }

        .appointment-booked-label {
            font-size: 14px;
            line-height: 21px;
            font-weight: 400;
            text-align: left;
            color: #2e353a;
            border-right: 1px solid #d4e1ea;
        }

        .appointment-booked-date {
            font-size: 16px;
            line-height: 24px;
            font-weight: 700;
            text-align: left;
            color: #006cb6;
            padding-left: 32px;
        }

        .action-button {
            margin-top: 40px !important;
            text-align: center !important;
        }

        .btn-close {
            width: 180px !important;
            border: 1px solid #006cb6 !important;
            border-radius: 27px !important;
            padding: 8px 32px 32px !important;

            .btn-close-text {
                font-size: 16px;
                line-height: 24px;
                font-weight: 700;
                text-align: center;
                color: #006cb6;
            }

            .btn-icon {
                font-size: 14px;
                padding-right: 10px;
            }
        }
    }
    //end kisoku modals

    .working-regulation,
    .kisoku-meetings {
        .separation-color {
            color: #d4e1ea !important;
        }

        .primary-btn {
            width: auto;
            background-color: $orange-4;
            color: white;
        }

        .secondary-button {
            margin-left: 20px;
            width: auto;
            color: $orange-4;
            border-color: $orange-4;
            background-color: white;
        }

        @keyframes inAnimation {
            0% {
                opacity: 0;
                visibility: hidden;
            }
            100% {
                opacity: 1;
                visibility: visible;
            }
        }

        @keyframes outAnimation {
            0% {
                opacity: 1;
            }
            100% {
                opacity: 0;
                visibility: hidden;
            }
        }

        .meeting-details {
            display: flex;
            font-size: 14px;
            margin-bottom: 40px;

            &-discussion-title {
                font-size: 18px;
                color: $blue-1;
                font-weight: 700;
            }

            &-date {
                font-size: 16px;
                color: $blue-1;
                font-weight: 700;
            }

            &-show-transition {
                transition: all 2s;
                opacity: 0;
                display: none;
            }

            &-opacity-change {
                opacity: 1;
                display: flex;
            }

            &-background {
                background-color: $gray-8;
                align-items: center;
                border-radius: 4px !important;
            }

            &-requirements {
                background-color: #ffffff;
                padding: 12px;
            }

            &-border {
                border-color: #d4e1ea !important;
            }

            &-divider,
            &-label-title {
                align-items: center !important;
            }

            &-remarks-title {
                font-size: 14px;
                font-weight: 700;
            }

            &-title {
                margin: 0 25px 0 10px;
                align-content: center;
                text-align: center !important;
                color: #006cb6;
                font-size: 24px;
                font-weight: 700 !important;
                line-height: 36px;
            }

            &-type-title {
                margin: 0 25px 0 10px !important;
                align-content: center !important;
                color: #2e353a !important;
                font-size: 14px !important;
                font-weight: 700 !important;
                line-height: 21px !important;
            }

            &-card {
                width: 110px !important;
            }

            &-status {
                margin: auto;
                padding: 2px 12px !important;
            }

            &-back-button {
                color: $blue-1;
                border-color: $blue-1;
            }

            &-back-button:hover {
                background-color: $gray-8;
            }

            &-accordion-button {
                color: white;
                border-color: $blue-1;
                background-color: $blue-1;
            }

            .card-container {
                display: flex;
                justify-content: center;
                align-self: center;
            }

            .status {
                &-scheduled {
                    color: #e97306 !important;
                    font-weight: 400 !important;
                }

                &-finished {
                    color: #006cb6 !important;
                    font-weight: 400 !important;
                }

                &-cancelled {
                    color: #848f97 !important;
                    font-weight: 400 !important;
                }

                &-not-implemented {
                    color: #848f97 !important;
                    font-weight: 400 !important;
                }

                &-default {
                    color: #848f97 !important;
                    font-weight: 400 !important;
                }
            }
        }

        .title {
            color: #006cb6;
            font-size: 24px;
        }

        .upload-note {
            font-size: 12px;
            line-height: 24px;

            &-right {
                font-size: 12px;
                line-height: 24px;
                text-align: left;
                background-color: $gray-1;
                border-radius: 3px;
                padding: 10px;
            }
        }

        &-header {
            margin-left: 24px !important;
        }

        &-table {
            .consultation-list-card-status {
                text-align: center;
                display: block;
                width: 100px !important;
            }
            .status {
                &-waiting {
                    background: $brown-1;
                    border: 1px solid $brown-2;
                    color: $brown-3 !important;
                }

                &-finished {
                    background-color: white;
                    border: 1px solid $blue-1;
                    color: $blue-1 !important;
                }

                &-applied {
                    border: 1px solid $green-4;
                    color: $green-4 !important;
                }

                &-canceled {
                    border: 1px solid $gray-9;
                    color: $gray-9 !important;
                }

                &-scheduled {
                    background: #fcf1e6 !important;
                    border: 1px solid #e97306 !important;
                    color: #e97306 !important;
                }

                &-past-due {
                    background: $purple-1;
                    border: 1px solid $purple-3;
                    color: $purple-3;
                }

                &-not-implemented {
                    border: 1px solid $gray-9;
                    color: $gray-9 !important;
                    background-color: #f4f5f5;
                }
            }

            .column-title {
                font-size: 12px !important;
                font-weight: bold !important;
            }

            .custom-row-class.finished {
                background-color: #f2f7fb !important;
            }

            .ant-table {
                table {
                    border-spacing: 0 1em !important;
                }

                .ant-table-thead {
                    .ant-table-cell {
                        background-color: transparent !important;

                        &::before {
                            height: 0 !important;
                        }
                    }

                    & > tr > th {
                        border-bottom: 0 !important;
                    }
                }

                .ant-table-tbody {
                    & > tr > td {
                        border-top: 1px solid rgba(212, 225, 234, 1) !important;
                        border-bottom: 1px solid rgba(212, 225, 234, 1) !important;
                    }

                    & > tr.ant-table-measure-row {
                        display: none;
                    }

                    & > tr > td:first-child {
                        border-left: 1px solid rgba(212, 225, 234, 1) !important;
                        border-radius: 4px 0 0 4px !important;
                    }

                    & > tr > td:last-child {
                        border-right: 1px solid rgba(212, 225, 234, 1) !important;
                        border-radius: 0 4px 4px 0 !important;
                    }
                }

                .blue-color-icon {
                    position: relative;
                    right: 0;
                    transition: right 0.3s ease-in-out;
                    color: rgba(0, 108, 182, 1);
                }

                .ant-table-row {
                    height: 77.25px;
                    cursor: pointer;

                    &:hover .blue-color-icon {
                        right: -10px;
                    }
                }
            }
        }

        .post-upload-note {
            font-size: 14px;
            line-height: 26px;
            width: 60%;
            margin: auto;
        }

        .text-right {
            text-align: right;
        }

        .title {
            margin-bottom: 25px;
        }

        .uploaded-file {
            border: 1px dashed $light-blue-3;
            background: white;
            width: 750px;
        }

        .remove-uploaded-file-btn {
            border: none;
            background: none;
            color: black;
            position: absolute;
            right: 0;
            top: 0;
            margin: 10px;
        }

        .ant-upload.ant-upload-drag p.ant-upload-drag-icon {
            margin-bottom: 5px;
        }

        .kisoku-upload-container {
            align-items: center;
            display: flex;
        }

        .pdf-upload-icon {
            font-size: 2em;
        }

        .ant-upload.ant-upload-drag p.ant-upload-drag-icon .anticon {
            color: $gray-5;
            font-size: 38px;
        }

        .kisoku-uploaded-file-table {
            button.hidden-button {
                visibility: hidden !important;
            }
        }

        .kisoku-action-file-table {
            button.hidden-button {
                visibility: hidden !important;
            }
        }

        .action-buttons {
            float: right;

            button.filter-button {
                color: black;
                border: 1px solid $gray-6;
                padding: 3px 10px;
                background: white;
            }

            .ant-input-search-button {
                border: 1px solid $gray-6 !important;
                background-color: white;
            }

            .ant-btn-dangerous {
                background: $red-1;
            }
        }
    }

    .kisoku-meetings-modal {
        .ant-modal-body {
            padding: 50px 13% !important;
        }
    }

    .kisoku-meetings {
        .title-helper-text {
            color: $gray-5;
            font-size: 11px;
            margin-left: auto;
            margin-top: auto;
            margin-bottom: auto;
        }

        .copy-button {
            background: $light-blue-1;
        }

        .meeting-datetime {
            color: #66b828;
        }

        .subtitle {
            display: flex;
            align-items: center;
            color: #2e353a;
            font-weight: 400;
            line-height: 21px;
            font-size: 14px;
        }

        .status {
            &-scheduled {
                color: #006bb5;
            }

            &-waiting {
                color: #f4a353;
            }

            &-finished {
                color: $green-3;
            }

            &-canceled {
                color: $red-3;
            }
        }
    }

    .card-container {
        display: flex;
        width: 100%;
        justify-content: center;
        align-self: center;

        &.finished {
            border: 1px solid $light-blue-3;
            background: #ffffff;
        }

        &.cancelled {
            border: 1px solid $gray-7;
            background: #ffffff;
        }

        &.not-implemented {
            border: 1px solid $gray-7;
            background: #f4f5f5;
        }

        &.scheduled {
            border: 1px solid $orange-4;
            background: #fcf1e6;
        }

        &.default {
            border: 1px solid $gray-7;
            background: #ffffff;
        }

        &.dark-gray {
            border: 1px solid $gray-4;
            background: rgba(196, 196, 196, 0.4);
            color: $gray-4;
        }

        &.gray {
            border: 1px solid $gray-3;
            background: $gray-2;
        }

        &.light-blue {
            border: 1px solid $light-blue-2;
            background: $light-blue-1;
        }

        &.orange {
            border: 1px solid rgba(196, 196, 196, 0.4);
            background: #fff5ec;
        }

        &.green {
            border: 1px solid $green-2;
            background: $green-1;
        }

        &.red {
            border: 1px solid $red-2;
            background: $red-1;
        }

        &.purple {
            border: 1px solid $purple-2;
            background: $purple-1;
        }

        &:not(:last-child) {
            margin-bottom: 15px;
        }

        .status {
            &-scheduled {
                color: $orange-4;
            }

            &-finished {
                color: $light-blue-5;
            }

            &-cancelled {
                color: $gray-7;
            }

            &-not-implemented {
                color: $gray-7;
            }

            &-default {
                color: $gray-7;
            }
        }
    }

    .instruction-container {
        &-one {
            width: 670px;
        }

        &-two {
            width: 770px;
        }
    }

    .file-upload-input {
        width: 750px;
    }

    @media (max-width: 1280px) {
        .dashboard-content {
            padding: 135px 105px 100px;
        }
        .dashboard-sider {
            left: auto;
        }
    }
}
