@import '~antd/dist/antd.css';
@import 'bootstrap';
@import 'variables';

// main
@import 'desktop/main';

// pages
@import 'desktop/landing';
@import 'desktop/public_support';
@import 'desktop/work_regulation';
@import 'desktop/dashboard';

// components
@import 'desktop/components/landing_navbar';
@import 'desktop/components/landing_footer';
@import 'desktop/components/dashboard_navbar';
@import 'desktop/components/inquiry';
@import 'desktop/components/consultation';
@import 'desktop/components/consultation_detail';
@import 'desktop/components/work_regulation_detail';

@media all and (max-width: 700px) {
    @import 'mobile/main';

    @import 'mobile/public_support';
    @import 'mobile/dashboard';
    @import 'mobile/work_regulation';
    @import 'mobile/landing';

    @import 'mobile/components/landing_navbar';
    @import 'mobile/components/landing_footer';
    @import 'mobile/components/dashboard_navbar';
    @import 'mobile/components/consultation';
    @import 'mobile/components/inquiry';
}
