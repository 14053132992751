@import 'src/assets/styles/variables';

body#public-support {
    .container {
        margin: 55px auto;
        min-height: calc(100vh - ($navbar-height + $footer-height));
    }

    section.header {
        position: relative;
        height: 500px;
        background: url('../../images/kouteki_landing_header.jpg') center no-repeat;
        width: 100%;
        background-size: cover;
        background-position-y: 0;
        display: flex;
        justify-content: center;
        align-items: center;

        img {
            width: 30%;
        }

        .content {
            height: 500px;
            display: flex;
            justify-content: center;
            align-items: center;
        }
    }

    section.sub-header {
        position: relative;
        background: url('../../images/kouteki_header2.png') center no-repeat;
        width: 100%;
        background-size: cover;
        height: 225px;

        img {
            width: 30%;
        }

        .content {
            background: rgba(19, 90, 117, 0.5);
            background-size: cover;
            width: 100%;
            justify-content: center;
            align-items: center;
            display: flex;
            height: 100%;

            .title {
                margin-top: 10%;
                font-size: 36px;
                color: #ffffff;
            }
        }
    }

    section.content {
        padding: 20px 0 100px 0;
        min-height: calc(100vh - ($navbar-height + $footer-height));
        display: flex;
        flex-direction: column;
        margin: 0 auto;
        width: 66%;
    }

    section.home-content {
        &:not(:last-child) {
            margin-bottom: 50px !important;
        }

        .title {
            color: #01a0dd;
            font-size: 24px;
            font-weight: bold;
            margin-bottom: 15px;
        }

        .title,
        p {
            text-align: center;
        }

        p {
            font-size: 16px;
        }

        .concept-meaning {
            .ant-card {
                min-height: 180px;
                font-size: 16px;
                line-height: 24px;
                background: $light-blue-1;
                border: 1px solid $light-blue-2;
            }

            .concept-title {
                margin-bottom: 10px;
                font-size: 18px;
                font-weight: bold;
            }
        }
    }

    section.diagnose-result-container {
        padding-bottom: 30px;
        position: relative;

        .title {
            color: $blue-1;
            font-size: 40px;
            font-weight: 700;
            margin-top: 16px;
            margin-bottom: 40px;
            position: relative;
            text-decoration: underline;
            text-underline-offset: 10px;
            z-index: 1;
        }

        .title::after {
            content: '';
            position: absolute;
            bottom: 0;
            left: 0;
            width: 100%;
            height: 4px;
            background-color: #e3e9ec;
            z-index: -1;
        }

        .subtitle .instruction-content {
            font-size: 16px;
        }

        .subtitle-list {
            font-size: 14px;
            margin-bottom: 40px;
        }

        .instruction-list {
            margin: 3px 0;
            font-size: 12px;
        }

        .instruction-container {
            display: grid;
            gap: 20px;
            grid-template-columns: repeat(auto-fit, minmax(250px, 1fr));
            padding-top: 8px;
        }

        .instruction-card .instruction-btn-section {
            display: flex;
            flex-direction: column;
            box-sizing: border-box;
        }

        .instruction-btn-section .checkbox-form .ant-checkbox-wrapper {
            color: #e97306 !important;
            border: 1px solid #e97306;
            border-radius: 50px;
            padding: 10px 20px;
        }

        .instruction-image img {
            width: 100%;
            height: auto;
            border: 1px solid #ddd;
            padding: 20px;
        }

        .instruction-title {
            span {
                border: 1px solid $blue-1;
                padding: 1px 10px !important;
                font-size: 20px;
                margin-right: 10px;
            }
            color: $blue-1;
            font-size: 24px;
            margin: 18px 0;
            font-weight: 700;
        }

        .instruction-notes {
            span {
                color: $blue-1;
                font-weight: bold;
            }
            font-size: 14px;
            margin-top: 40px;
            background-color: $light-blue-6;
            padding: 20px;
        }

        .instruction-btn-group {
            button {
                padding: 10px 20px;
                height: 50px;
                border-radius: 50px !important;
            }
            display: flex;
            flex-direction: row;
            gap: 9%;
            margin-top: 40px;
        }

        .instruction-btn-group > div:nth-child(2) {
            margin-right: 170px !important;
        }

        .left-outline {
            margin-right: 10px;
            font-size: 12px !important;
        }

        .subtitle {
            font-size: 16px;
            color: #555;
        }

        .detail-title-instruction {
            background-color: $blue-1;
            padding: 9px;
            color: white;
            font-size: 24px;
            margin-bottom: 30px;
        }
        .title-instruction {
            color: $blue-1;
            font-size: 40px;
            font-weight: 700;
            margin-top: 30px;
            margin-bottom: 60px;
            padding-bottom: 10px;
            border-bottom: 4px solid $blue-1;
        }

        .sub-header {
            font-size: 16px;
            font-weight: 400;
            line-height: 24px;
            margin-bottom: 60px;
        }

        .funds-result {
            font-size: 18px;
            color: #2e353a;
            font-weight: 700;
        }

        .potential-funds {
            font-size: 24px;
            color: #e97306;
            padding-left: 16px;
            padding-right: 40px;
        }

        .invalid-funds {
            font-size: 24px;
            color: $blue-1;
            padding-left: 16px;
            padding-right: 40px;
        }

        .consultation-text {
            display: flex;
        }

        .consultation-label {
            height: 30px;
        }

        .external-link-text {
            display: flex;
        }

        .external-link {
            height: 30px;
        }
    }
    section.diagnose-result-schedule-container {
        .title {
            color: $blue-1;
            font-size: 40px;
            font-weight: bold;
            margin-bottom: 40px;
            border-bottom: 5px solid;
            position: relative;
            padding-bottom: 5px;
        }

        .step-title {
            span {
                border: 1px solid $blue-1;
                padding: 1px 10px !important;
                margin-right: 10px;
                background-color: white;
            }
            .list {
                margin-top: 2px;
                color: black;
                font-size: 12px;
            }
            display: flex;
            flex-wrap: wrap;
            align-items: center;
            background-color: $light-blue-6;
            color: $blue-1;
            padding: 9px;
            font-size: 18px;
            margin: 18px 0;
            font-weight: 700;
        }
        .calendar-error-message {
            color: red;
            margin: 0 0 10px 20px;
        }
        .ant-form-item-has-error textarea {
            background-color: #fcf6f4;
        }

        .step-title span,
        .step-title .list {
            white-space: nowrap;
        }

        // Make list at the end
        .step-title .list {
            margin-left: auto;
        }

        .confirmation-form #remarks:not(:focus) {
            background-color: #FAFCFD;
        }

        .schedule-btn-group {
            button {
                padding: 10px 20px;
                height: 50px;
                border-radius: 50px !important;
            }
            display: flex;
            flex-direction: row;
            margin-top: 40px;
        }

        .left-outline {
            margin-right: 10px;
            font-size: 12px !important;
        }

        .schedule-btn-section {
            display: flex;
            flex-direction: column;
            box-sizing: border-box;
        }

        .schedule-calender-card {
            box-shadow: 0 0 15px rgba(0, 0, 0, 0.2); /* Shadow all around */
            padding: 25px;
            border-radius: 8px;
        }

        .schedule-summary {
            font-size: 18px;
            line-height: 22px;
            color: black;
            font-weight: bolder;

            div {
                display: inline-block;
                border-bottom: 2px solid $blue-1;
                line-height: 25px;

                span.schedule-datetime {
                    color: $blue-1;
                }
            }
        }

        .schedule-icons {
            span {
                margin: 0 10px !important;
            }
            img {
                margin-bottom: 3px;
            }
        }

        .schedule-checker-icon {
            display: flex;
            justify-content: center;
            align-items: center;
            height: 40%;

            svg circle {
                stroke: #006CB6;
            }
        }

        .ant-picker-panel .ant-picker-calendar-date {
            height: 80px;
            padding: 4px 12px 0;
            margin: 0;
            border-top: none;
        }

        .ant-picker-calendar-full .ant-picker-panel .ant-picker-cell-selected .ant-picker-calendar-date {
            background-color: #006CB6 !important;

            .ant-picker-calendar-date-value {
                color: #FFFFFF;
            }

            .schedule-checker-icon {
                svg circle {
                    stroke: #FFFFFF;
                }
            }
        }

        .ant-picker-cell {
            &:hover {
                &:not(.ant-picker-cell-selected):not(.ant-picker-cell-range-start):not(.ant-picker-cell-range-end):not(.ant-picker-cell-range-hover-start):not(.ant-picker-cell-range-hover-end) {
                    .ant-picker-cell-inner {
                        background: #E5F0F7;
                    }
                }
            }
        }

        .month-picker {
            display: flex;
            align-items: center;

            &-text {
                font-weight: bold;
                font-size: 16px;
            }

            button {
                font-size: 8px;
                padding: 0 5px;
                margin: 0 10px !important;
                height: 20px;
                border: 1px solid #006cb6;
                color: #006cb6;
            }
        }

        .schedule-time {
            width: 100% !important;
            text-align: center;
        }

        .schedule-time {
            .ant-radio-wrapper {
                border: 1px solid #a2adb4;
                border-radius: 4px;
                padding: 20px;
                margin: 6px 0;
                width: 100%;
                justify-content: center;
                transition: background-color .2s ease-in-out;

                &:hover {
                    background-color: #E5F0F7;
                }
            }

            .ant-radio-wrapper-disabled {
                background-color: #f4f5f5;
            }

            .ant-radio-wrapper-checked {
                border: 2px solid #006CB6;
            }
        }

        .ant-picker-cell-disabled {
            background-color: #f4f5f5;
        }

        /* When .holiday exists within .ant-picker-cell-inner, style the .ant-picker-calendar-date-value */
        .ant-picker-cell-inner.ant-picker-calendar-date:has(.holiday) .ant-picker-calendar-date-value {
            color: $red-4 !important;
        }

        // Change color of sunday header
        .schedule-calendar > div > div > div > table > thead > tr > th:nth-child(1) {
            color: red;
        }

        // Change color of sunday header
        .schedule-calendar > div > div > div > table > thead > tr > th {
            border: 1px solid $gray-7;
            text-align: center;
        }

        // Change color of sunday date
        .schedule-calendar > div > div > div > table > tbody > tr > td:nth-child(1) {
            color: $red-4 !important;
        }

        // Change color of saturday date
        .schedule-calendar > div > div > div > table > thead > tr > th:nth-child(7) {
            color: $blue-1 !important;
        }

        // Change color of sunday date
        .schedule-calendar > div > div > div > table > tbody > tr > td:nth-child(7) {
            color: $light-blue-7 !important;
        }

        // Change color of sunday date
        .schedule-calendar > div > div > div > table > tbody > tr > td {
            border: 1px solid $gray-7;
        }

        .ant-picker-calendar-date-value {
            text-align: start !important;
        }

        // Make cell content not overlap below
        .ant-picker-calendar-date-content {
            padding-top:6px;
            height: auto !important
        }
    }

    section.diagnose-schedule-finished-container {
        display: flex;
        flex-direction: column;
        align-items: center;
        justify-content: center;
        text-align: center;

        .title {
            color: $blue-1;
            font-size: 40px;
            font-weight: bold;
            margin-bottom: 40px;
        }

        .subtitle {
            font-size: 18px;
            font-weight: bold;
            margin-bottom: 40px;
        }

        .schedule {
            background-color: $light-blue-6;
            width: 430px;
            padding: 17px;
            color: $blue-1;
            font-size: 24px;
            font-weight: bold;
            margin-bottom: 40px;
        }
        .schedule-btn-section {
            button {
                padding: 10px 80px;
                height: 50px;
                border-radius: 50px !important;
                background: $blue-1;
            }

            margin-top: 30px;
            display: flex;
            flex-direction: column;
            box-sizing: border-box;
        }
    }

    .schedule-title-modal {
        font-size: 32px;
        margin: 10px;
        font-weight: bolder;
    }

    .schedule-subtitle-modal {
        color: #e95532;
    }

    section.schedule-modal-container {
        .schedule-content-modal .ant-row {
            border-top: 1px solid #d4e1ea;

            font-size: 14px;
            padding: 10px 0;
        }
        .schedule-content-modal .ant-row:last-child {
            border-bottom: 1px solid #d4e1ea;
        }
        .schedule-btn-group {
            button {
                padding: 10px 20px;
                height: 50px;
                border-radius: 50px !important;
            }
            display: flex;
            flex-direction: row;
            gap: 10px;
            margin-top: 10px;
        }

        .left-outline {
            margin-right: 10px;
            font-size: 12px !important;
        }

        .summary-schedule {
            color: $blue-1;
            font-weight: bolder !important;
        }

        .schedule-btn-section {
            display: flex;
            flex-direction: column;
            box-sizing: border-box;
            margin-left: 50px;
        }
    }

    .schedule-limit-button {
        button {
            margin-top: 20px;
            padding: 10px 80px;
            height: 50px;
            border-radius: 50px !important;
            background: $blue-1;
        }
    }
    .schedule-limit-modal {
        width: 650px !important;
    }
    .search-btn {
        text-align: center;
        margin-bottom: 160px;
        margin-top: 5px;
        margin-left: 20px;

        .ant-btn {
            border-radius: 40px !important;
            color: $blue-1;
            font-size: 16px;
            height: 56px;
            font-weight: bold;
            border: 1px solid $blue-1;
            padding: 16px 32px;
        }
    }

    footer.landing-footer {
        background-image: url('../../images/footer_banner.png');
        color: #ffffff;

        .footer-wrapper {
            background: linear-gradient(180deg, rgba(0, 151, 254, 0.7) -8.91%, rgba(0, 12, 20, 0.7) 74%);
        }
    }

    .search-form-container {
        width: 75%;
        margin: auto;
    }

    .search-form-margin-top {
        margin-top: -10% !important;
    }

    .search-form-card form {
        .ant-form-item-label label {
            font-weight: bold;
        }

        .ant-btn {
            background-color: #2a6cb0;
            color: #fff;

            &:hover {
                background-color: #017fd6;
            }

            &:not(.search-keyword-btn) {
                height: 60px;
                width: 180px;
            }
        }
    }

    .list-center {
        margin-left: auto;
        margin-right: auto;
        width: 37%;
        border: 1px dashed #01a0dd;
        padding: 20px;
    }

    .sub-description {
        margin-top: 5px;
        font-size: 11px;
    }

    .kouteki-card-size {
        min-height: 320px !important;
    }

    .checkbox-size * {
        width: 20px;
        height: 20px;
    }

    .link-desc {
        font-size: 16px;
        font-weight: 700;
    }

    .pop-up-table-list {
        vertical-align: baseline;
    }

    .service-card {
        height: 500px;
        text-align: center;

        img {
            height: 200px;
        }

        .card-title {
            margin: 15px 0;
            font-size: 18px;
            font-weight: bold;
        }

        p {
            font-size: 12px;
            text-align: left;
        }
    }

    .arrow-icon {
        font-size: 40px !important;
    }

    .ant-carousel {
        .carousel-page {
            padding: 20px 10px;
        }

        .slick-prev,
        .slick-prev:hover {
            color: #2a6cb0;
        }

        .slick-next,
        .slick-next:hover {
            color: #2a6cb0;
        }

        .ant-btn {
            color: #2a6cb0;
            border-color: #2a6cb0;
        }

        p {
            height: 150px;
            display: flex;
            justify-content: center;
            align-items: center;
            font-size: 19px !important;
        }

        .recommendation {
            background: $light-blue-1;
        }
    }

    .search-content {
        .page-count {
            font-weight: bold;
            font-size: 18px;
            text-align: center;
            margin-bottom: 10px;
        }

        .search-result {
            .ant-checkbox-wrapper {
                width: calc(100% - 60px);
            }

            .ant-checkbox-wrapper > span:nth-child(2) {
                line-height: 18px;
                overflow: hidden;
            }

            .content-wrapper {
                display: flex;
                overflow: hidden;
            }

            .title,
            .description {
                white-space: nowrap;
                text-overflow: ellipsis;
                overflow: hidden;
            }

            .title {
                min-width: 45vh;
                margin-right: 15px;
                font-weight: bold;
            }

            .description {
                min-width: 55vh;
            }

            .ant-list-split .ant-list-item {
                border: 1px solid #dedede;
                margin-bottom: 15px;
                padding: 12px;
                border-radius: $border-radius;

                &:hover {
                    background-color: #f0f0f0;
                }
            }
        }

        .submit-button {
            height: 32px;
            width: 150px;
        }
    }

    .carousel-page,
    .search-result {
        .ant-btn:not(:disabled) {
            border-color: #006bb5;
            color: #006bb5;

            &:hover {
                background-color: #006bb5;
                color: #fff;
            }
        }
    }

    .note {
        font-size: 16px;
        font-weight: 400;
        line-height: 24px;
    }

    .consult-badge {
        background: #66b828;
        color: #ffffff;
        padding: 10px;
        text-align: center;
        display: inline-block;
    }

    .diagnose-list {
        background: #ffffff;
        align-items: center;
        margin-top: 50px;
        margin-bottom: 20px;
        padding: 10px;
        border-radius: 4px;
        box-shadow: 0 0 20px 0 rgba(0, 0, 0, 0.15);

        .header-title {
            color: #e97306 !important;
            font-size: 28px;
            font-weight: bold;
            margin-left: 10px;
            margin-bottom: 30px;
        }

        .header-title-error {
            color: $blue-1 !important;
            font-size: 28px;
            font-weight: bold;
            margin-left: 10px;
            margin-bottom: 30px;
        }

        .ant-card-head {
            background-color: #e97306;
        }

        .ant-card-bordered {
            border: 0;
        }

        .card-content {
            background-color: #fcf1e6;
            color: #2e353a;
            font-size: 14px;
            margin-bottom: 30px;
        }

        .ant-card-head-title {
            color: #ffffff;
            font-size: 16px !important;
            font-weight: 700 !important;
            line-height: 24px !important;

            .title-card {
                color: #fbf28f;
            }
        }

        .diagnosis-avatar {
            border-color: #006bb5;
            margin-bottom: 30px;
        }

        .first-content {
            padding: 15px;
            font-size: 14px;
        }

        .inner-content {
            background-color: #ffffff;
            margin: 30px 20px;
            padding: 32px 24px;
            border: 4px solid #fae3cd;
        }

        .not-allow {
            margin: 30px 0;
        }

        .info-title {
            font-size: 16px;
            font-weight: bold;
            background-color: #fcf1e6;
        }

        .info-title-error {
            font-size: 16px;
            font-weight: bold;
            background-color: $light-blue-6;
        }

        .info {
            padding: 8px 16px;
        }

        .info-subtitle {
            font-size: 14px;
        }

        .box-content {
            background-color: #ffffff;
            margin: 30px 0;
            padding: 32px 24px;
            border: 4px solid #fae3cd;
        }

        .box-content-error {
            background-color: #ffffff;
            margin: 30px 0;
            padding: 32px 24px;
            border: 4px solid #cce1f0;
        }

        .grant-name {
            font-size: 20px;
            color: #2e353a;
        }

        .add-to-consult {
            align-items: flex-end;
            text-align: center;
            padding: 16px 24px;
            border: 1px solid #e97306;
            border-radius: 40px !important;
        }

        .checkbox-consult {
            font-size: 14px;
            color: #e97306;
        }

        .external-link {
            text-align: right;
            padding-top: 3px;
        }

        .grant-details-page {
            font-size: 20px;
            font-weight: bold;
            color: #2e353a;
        }

        .link-kanri {
            font-size: 16px;
            color: #2e353a;
        }

        .anticon {
            padding-left: 5px !important;
        }

        .ant-col-24 {
            text-align: center;
            margin-bottom: 15px;
        }

        .ant-btn {
            border-radius: 40px !important;
        }

        .btn-text {
            font-size: 18px;
            color: #ffffff;
            margin-top: 5px;
            margin-left: 20px;
        }

        .expert-consultation {
            background-color: #e97306;
            height: 70px;
            padding: 15px 30px;
            text-align: center;

            &:hover {
                background-color: #d86404;
            }
        }
    }

    .footer-items a {
        color: #ffffff !important;
    }

    .search-funds-details {
        .content {
            white-space: pre-line;
        }

        .title {
            font-size: 24px;
            color: #01a0dd;
            display: inline-block;
            font-weight: bold;
            margin-bottom: 10px;
        }

        .badge {
            background-color: #f6ffed;
            border: 1px solid #b7eb8f;
            border-radius: 2px;
            color: #52c41a;
            font-size: 12px;
            padding: 2px 5px;
            vertical-align: middle;
            margin-left: 10px;
        }

        section {
            margin-bottom: 20px;

            .name {
                color: #01a0dd;
                font-weight: bold;
            }
        }

        .helper-text {
            border: 1px solid #000000;
            color: #ff4d4f;
            padding: 5px;
            display: inline-block;
        }

        .requirements-section {
            margin-top: 30px;
            background-color: #f0f0f0;
            text-align: center;
            padding: 50px;

            .requirements {
                font-size: 36px;
                color: #444444;
                font-weight: bold;
            }
        }

        .more-details {
            background-color: #eaf0f7;
            color: #2a6cb0;
            padding: 30px 20px;
            width: 100%;
            align-items: center;

            button.ant-btn {
                background-color: #006bb5;
                color: #fff;
            }

            button.external {
                float: right;
            }
        }
    }

    .checkbox-desc * {
        font-size: 16px !important;
    }

    button.diagnose-button {
        height: 40px;
        width: 320px;
        background-color: #006bb5;
        color: #fff;
    }

    .match-failed-modal .ant-modal-body {
        text-align: center;
        padding: 52px 80px;
    }

    .match-failed-modal,
    .match-loading-modal {
        .title {
            font-size: 16px;
            font-weight: bold;
        }
    }

    .topic-list {
        padding-top: 0 !important;
        min-height: calc(100vh - ($navbar-height + $footer-height));
    }

    .topics {
        margin: 2vw 12vw;
        text-align: center;

        .ant-card {
            background: #f2f2f2;
            margin-top: 10px;

            .topic-date-col {
                font-size: 12px;
                padding: 20px;

                .topic-date-container {
                    background: #999999;
                    color: #ffffff;
                    padding: 2px;
                    text-align: center;
                }
            }

            .topic-text-col {
                font-size: 14px;
                text-align: justify;
                padding: 20px;
            }

            .topic-button-col {
                padding: 10px;

                .ant-btn {
                    width: 88px;
                    font-size: 11px;
                }
            }
        }
    }

    .topic-container {
        padding: 0 182px 30px 182px;
        min-height: calc(
            100vh - 225px // navbar
            - 250px // footer
            - 112px // breadcrumbs
        );

        .title {
            color: black;
            margin-bottom: 5px;
            font-size: 24px;
            font-weight: 700;
            line-height: 36px;
        }

        p.content {
            white-space: pre-line;
        }

        .badge {
            background-color: #f6ffed;
            border: 1px solid #b7eb8f;
            border-radius: 2px;
            color: #52c41a;
            font-size: 12px;
            padding: 2px 5px;
            vertical-align: middle;
        }
    }

    .instruction-modal {
        .title {
            color: #01a0dd;
            font-size: 24px;
            font-weight: bold;
            margin-bottom: 24px;
            text-align: center;
        }

        .ant-modal-body {
            padding-left: 5%;
        }

        .text-indent {
            display: inline-block;
            text-indent: 17px;
        }

        .btn-group {
            display: flex;
            gap: 10%;
            justify-content: center;
        }

        .note {
            border: 1px solid $light-blue-5;
            line-height: 19px;
            padding: 5px;
            margin: 10px 23px 10px 0;
        }

        .ant-image-mask {
            background: rgba(0, 0, 0, 0);
            align-items: flex-end;
            justify-content: flex-start;
            opacity: 1;

            &:hover {
                box-shadow: 0 2px 12px 0 rgba(0, 0, 0, 0.3);
            }

            .ant-image-mask-info {
                margin: 10px;
                padding: 1px 10px;
                background: rgba(0, 0, 0, 0.3);
                border-radius: 5px;
            }
        }
    }
}
