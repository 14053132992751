.consultation-status-card {
    .status-text {
        text-align: left;
    }
}

.consultation-cancel-modal {
    form {
        width: 100% !important;
    }

    .form-buttons {
        width: 100%;
    }
}

.consultation-specialist-card {
    .ant-image-img {
        max-width: 70px;
    }

    .profile-card {
        padding: 10px;

        &-buttons {
            button:first-of-type {
                margin-bottom: 5px;
            }
        }

        &-selection {
            column-count: 1;
            -webkit-column-count: 1;
            -moz-column-count: 1;
        }
    }

    .ant-collapse-icon-position-right > .ant-collapse-item > .ant-collapse-header .ant-collapse-arrow {
        position: absolute;
        top: 0;
        right: 0;
        left: unset;
        margin: 0;
        transform: unset;
        width: 125px;
    }
}

.consultation-list-card {
    .details-button {
        width: 100%;
    }
}


.consultation-list {
    .page-actions-items-search, .page-actions-items-select {
        width: auto !important;
    }
}