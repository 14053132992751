body#public-support {
    .search-form-container {
        width: 100%;
    }

    .service-card-container {
        width: 100%;
    }

    section.header {
        img {
            width: 65%;
        }
    }

    section.sub-header {
        .content {
            height: 100%;
            align-items: end;
        }
    }

    section.content {
        padding: 20px 10px 100px 10px;
        width: 90%;

        .search-result {
            .content-wrapper {
                overflow: auto;
                min-height: 30px;
            }
        }
    }

    .search-funds-details {
        .requirements-section {
            padding: 20px 10px;
        }

        .more-details {
            display: block;

            button.ant-btn {
                display: block;
                margin-left: 0;
                margin-top: 10px;
            }

            button.external {
                float: unset;
                margin: 20px auto 0;
            }
        }
    }

    .diagnose-buttons {
        display: block;
        text-align: center;

        .ant-space-item {
            margin-bottom: 10px;
        }
    }

    section.diagnose-result-container {
        .consultation-text {
            display: inline;
        }

        .external-link-text {
            display: inline;
        }

        .instruction-btn-group {
            display: flex;
            flex-direction: row;
            flex-wrap: wrap !important;
        }

        .instruction-btn-group > div:nth-child(2) {
            margin-right:0px !important;
        }
    }

    section.diagnose-result-schedule-container {
        .step-title {
            flex-direction: column;
            align-items: flex-start;
        }
        // Remove the left margin when stacked
        .step-title .list {
            margin-left: 0;
        }

        .schedule-summary {
            margin-top: 10px !important;
        }
    }

    section.diagnose-schedule-finished-container {
        .schedule {
            width: 100%;
        }
    }

    .match-failed-modal .ant-modal-body {
        padding: 24px;
    }

    .match-failed-modal,
    .match-loading-modal {
        .title {
            font-size: 14px;
            font-weight: bold;
        }
    }

    .service-card {
        height: 100%;
    }

    .ant-card {
        .topic-text-col {
            padding: 0 20px !important;
        }
    }

    .topic-list .title {
        padding: 20px 0 !important;
    }

    .topics {
        margin: 0 20px;
    }

    .topic-container {
        padding: 20px 10px 30px 10px;
        min-height: calc(
            100vh
            - 225px// navbar
            - 273.69px// footer
            - 42px // breadcrumbs
        );
    }

    .list-center {
        margin-left: auto;
        margin-right: auto;
        width: 60%;
        border: 1px dashed #01A0DD;
        padding: 40px;
    }

    .kouteki-card-size {
        min-height: 630px !important;
    }
}
