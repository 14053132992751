.consultation-details {
  &-cancel-modal {
      .title-modal {
          color: #2e353a;
          font-size: 32px;
          font-weight: 700;
          line-height: 48px;
          text-align: center;
          margin-bottom: 30px;
      }
      .subtitle-modal {
          color: #e95532;
          font-size: 14px;
          font-weight: 400;
          line-height: 21px;
          text-align: center;
          margin-bottom: 30px;
      }

      .cancel-info {
          display: flex;
          border-top: 1px solid #d4e1ea;
          color: #2E353A;
          font-size: 14px;
          font-weight: 400;
          line-height: 21px;

          &-left {
              position: relative;

              &::before {
                  position: absolute;
                  top: 50%;
                  right: 0;
                  width: 1.5px;
                  height: 18px;
                  background-color: #CCE1F0;
                  transform: translateY(-50%);
                  transition: background-color 0.3s;
                  content: "";
              }
          }
      }

      .form-buttons {
          display: flex;

          .general-button {
              border-radius: 27px !important;
              height: auto;
              font-size: 16px;
              font-weight: 700;
              line-height: 24px;
          }

          .cancel-button {
              width: 200px;
              padding: 16px;
              background: transparent;
              color: #006cb6;
              border: 1px solid #006cb6;
              transition: ease-in-out 0.4s;

              &:hover {
                  background: #006cb6;
                  color: #ffffff;
                  transition: ease-in-out 0.4s;
              }
          }

          .submit-button {
              width: 320px;
              padding: 16px;
              background: #006cb6;
              color: #ffffff;
              border: 1px solid #006cb6;
              transition: ease-in-out 0.4s;

              &:hover {
                  opacity: 0.7;
                  transition: ease-in-out 0.4s;
              }
          }
      }
  }
}

